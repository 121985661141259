define("ember-models-table/utils/emt/macros", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shownColumnsBody = void 0;

  var shownColumnsBody = function shownColumnsBody(processedColumns, colspanKey) {
    var skipCount = 0;
    return processedColumns.filter(function (c, index, columns) {
      var colspan = (0, _object.get)(c, colspanKey) || 1;
      var isVisible = c.isVisible;
      var nextHiddenCells = columns.slice(index + 1, index + colspan).filter(function (c) {
        return c.isHidden;
      });

      if (nextHiddenCells.length === colspan - 1 && !isVisible && colspan !== 1) {
        return false;
      }

      if (skipCount) {
        skipCount--;
        return false;
      }

      if (colspan === 1) {
        return isVisible;
      }

      if (colspan > 1) {
        skipCount = colspan - 1;
      }

      return true;
    });
  };

  _exports.shownColumnsBody = shownColumnsBody;
});
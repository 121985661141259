define("ember-collapsible-panel/components/cp-panel-body/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @shouldAnimate}}
  
    {{#this.liquidif @isOpen use='crossFade'}}
      <div class="cp-Panel-body-inner">
        {{yield}}
      </div>
    {{/this.liquidif}}
  
  {{else}}
  
    {{#if @isOpen}}
      <div class="cp-Panel-body-inner">
        {{yield}}
      </div>
    {{/if}}
  
  {{/if}}
  
  */
  {
    "id": "ffjhvGx4",
    "block": "[[[41,[30,1],[[[1,\"\\n\"],[6,[30,0,[\"liquidif\"]],[[30,2]],[[\"use\"],[\"crossFade\"]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"cp-Panel-body-inner\"],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,0],[14,0,\"cp-Panel-body-inner\"],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]]]],[\"@shouldAnimate\",\"@isOpen\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-collapsible-panel/components/cp-panel-body/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("ember-models-table/utils/emt/themes/ebs", ["exports", "@embroider/macros/es-compat", "@ember/debug"], function (_exports, _esCompat, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBsNavbar = _exports.getBsNav = _exports.getBsForm = _exports.getBsDropdown = _exports.getBsButton = void 0;

  var getBsButton = function getBsButton() {
    return (0, _esCompat.default)(require("ember-bootstrap/components/bs-button")).default;
  };

  _exports.getBsButton = getBsButton;

  var getBsForm = function getBsForm() {
    return (0, _esCompat.default)(require("ember-bootstrap/components/bs-form")).default;
  };

  _exports.getBsForm = getBsForm;

  var getBsDropdown = function getBsDropdown() {
    return (0, _esCompat.default)(require("ember-bootstrap/components/bs-dropdown")).default;
  };

  _exports.getBsDropdown = getBsDropdown;

  var getBsNav = function getBsNav() {
    return (0, _esCompat.default)(require("ember-bootstrap/components/bs-nav")).default;
  };

  _exports.getBsNav = getBsNav;

  var getBsNavbar = function getBsNavbar() {
    return (0, _esCompat.default)(require("ember-bootstrap/components/bs-navbar")).default;
  };

  _exports.getBsNavbar = getBsNavbar;
});
define("ember-models-table/utils/emt/string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.splitPropSortDirection = void 0;

  var splitPropSortDirection = function splitPropSortDirection() {
    var propSortDirection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var chunks = propSortDirection.split(':');
    var sortDirection = chunks.pop() || '';
    var propName = chunks.join(':');
    return [propName, sortDirection];
  };

  _exports.splitPropSortDirection = splitPropSortDirection;
});
define("ember-anchor/mixins/view-support", ["exports", "@ember/object/mixin", "@ember/runloop", "@ember/object/computed", "@ember/object", "ember-anchor/mixins/controller-support"], function (_exports, _mixin, _runloop, _computed, _object, _controllerSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    _anchorConfig: (0, _controllerSupport.injectConfig)(),
    anchorQueryParam: (0, _computed.oneWay)('_anchorConfig.anchorQueryParam'),
    init: function init() {
      this._super.apply(this, arguments);

      var controllerProp = this.get('a') ? 'a' : "controller.".concat(this.get('anchorQueryParam'));
      this.addObserver(controllerProp, this, this._onQpChanged);
    },
    _onQpChanged: function _onQpChanged() {
      var controllerProp = (0, _object.get)(this, 'a') ? 'a' : "controller.".concat(this.get('anchorQueryParam'));
      var qp = this.get('anchorQueryParam');
      var elem = document.querySelector("[data-".concat(qp, "=\"").concat(this.get(controllerProp), "\"]"));

      if (!elem) {
        return;
      }

      (0, _runloop.scheduleOnce)('afterRender', this, '_scrollToElemPosition');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._scrollToElemPosition();
    },
    _scrollToElemPosition: function _scrollToElemPosition() {
      var qp = this.get('anchorQueryParam');
      var qpVal = this.get((0, _object.get)(this, 'a') ? 'a' : "controller.".concat(qp));
      var elem = document.querySelector("[data-".concat(qp, "=\"").concat(qpVal, "\"]"));
      var offset = elem && elem.offsetTop;

      if (offset) {
        elem.scrollIntoView();
      }
    }
  });

  _exports.default = _default;
});
define("ember-anchor/mixins/controller-support", ["exports", "@ember/object/mixin", "@ember/application", "@ember/object", "@ember/object/computed"], function (_exports, _mixin, _application, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.injectConfig = injectConfig;

  function injectConfig() {
    return (0, _object.computed)(function () {
      var owner = _application.getOwner ? (0, _application.getOwner)(this) : this.container;
      return owner.lookup('config:ember-anchor');
    });
  }

  var _default = _mixin.default.create({
    anchorQueryParam: (0, _computed.oneWay)('_anchorConfig.anchorQueryParam'),
    _anchorConfig: injectConfig(),
    init: function init() {
      var qpValue = this.get('anchorQueryParam');
      this.queryParams = qpValue ? [qpValue] : [];

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
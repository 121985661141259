define("ember-parachute/decorators/-private/query-params-for", ["exports", "ember-parachute/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addQueryParamFor = addQueryParamFor;
  _exports.getQueryParamsFor = getQueryParamsFor;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var QP_MAP = new WeakMap();

  function getQueryParamsFor(klass) {
    QP_MAP.set(klass, QP_MAP.get(klass) || new _queryParams.default());
    return QP_MAP.get(klass);
  }

  function addQueryParamFor(klass, key, definition) {
    QP_MAP.set(klass, getQueryParamsFor(klass).extend(_defineProperty({}, key, definition || {})));
  }
});
define("ember-bootstrap/mixins/component-parent", ["exports", "@ember/runloop", "@ember/array", "@ember/object/mixin"], function (_exports, _runloop, _array, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for components that act as a parent component in a parent-child relationship of components
   *
   * @class ComponentParent
   * @namespace Mixins
   * @private
   */
  // eslint-disable-next-line ember/no-new-mixins
  var _default = _mixin.default.create({
    /**
     * Array of registered child components
     *
     * @property children
     * @type array
     * @protected
     */
    children: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('children', (0, _array.A)());
    },

    /**
     * Register a component as a child of this parent
     *
     * @method registerChild
     * @param child
     * @public
     */
    registerChild: function registerChild(child) {
      (0, _runloop.schedule)('actions', this, function () {
        this.children.addObject(child);
      });
    },

    /**
     * Remove the child component from this parent component
     *
     * @method removeChild
     * @param child
     * @public
     */
    removeChild: function removeChild(child) {
      (0, _runloop.schedule)('actions', this, function () {
        this.children.removeObject(child);
      });
    }
  });

  _exports.default = _default;
});
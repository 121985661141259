define("ember-bootstrap/utils/cp/type-class", ["exports", "@ember/object", "@ember/debug"], function (_exports, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = typeClass;

  function typeClass(prefix, typeProperty) {
    (false && !(typeof prefix === 'string') && (0, _debug.assert)('You have to provide a prefix for typeClass', typeof prefix === 'string'));
    (false && !(typeof typeProperty === 'string') && (0, _debug.assert)('You have to provide a typeProperty for typeClass', typeof typeProperty === 'string'));
    return (0, _object.computed)('outline', 'type', function () {
      var type = this[typeProperty] || 'default';
      (false && !(typeof type === 'string' && type !== '') && (0, _debug.assert)('The value of `type` must be a string', typeof type === 'string' && type !== ''));

      if (this.outline) {
        return "".concat(prefix, "-outline-").concat(type);
      }

      return "".concat(prefix, "-").concat(type);
    });
  }
});
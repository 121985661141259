define("ember-models-table/utils/emt/fmt", ["exports", "@ember/array", "@ember/template"], function (_exports, _array, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fmt;

  function fmt() {
    var str = (arguments.length <= 0 ? undefined : arguments[0]) || '';
    var cachedFormats = arguments.length <= 1 ? undefined : arguments[1];

    if (!(0, _array.isArray)(cachedFormats) || arguments.length > 2) {
      cachedFormats = new Array(arguments.length - 1);

      for (var i = 1, l = arguments.length; i < l; i++) {
        cachedFormats[i - 1] = i < 0 || arguments.length <= i ? undefined : arguments[i];
      }
    } // first, replace any ORDERED replacements.


    var idx = 0; // the current index for non-numerical replacements

    if ((0, _template.isHTMLSafe)(str)) {
      str = str.toString();
    }

    return str.replace(/%@([0-9]+)?/g, function (_, argIndex) {
      var argIndexNumeric = argIndex ? parseInt(argIndex, 10) - 1 : idx++;
      return cachedFormats[argIndexNumeric];
    });
  }
});
define("ember-parachute/decorators/query-param", ["exports", "@ember/array", "ember-parachute/-private/symbols", "ember-parachute/decorators/-private/query-params-for"], function (_exports, _array, _symbols, _queryParamsFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = queryParam;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function createDescriptor(desc, qpDefinition) {
    qpDefinition = qpDefinition || {};

    var descriptor = _objectSpread(_objectSpread({}, desc), {}, {
      finisher: function finisher(klass) {
        (0, _queryParamsFor.addQueryParamFor)(klass, desc.key, qpDefinition);
        klass.reopen((0, _queryParamsFor.getQueryParamsFor)(klass).Mixin);
        var proto = klass.proto(); // Remove duplicate queryParams created by the multiple mixins

        if (Array.isArray(proto.queryParams)) {
          var queryParams = (0, _array.A)(_toConsumableArray(proto.queryParams));
          var parachuteQueryParams = queryParams.filterBy(_symbols.PARACHUTE_QPS, true); // Keep the newest one

          parachuteQueryParams.pop(); // Remove the old ones

          queryParams.removeObjects(parachuteQueryParams);
          proto.queryParams = queryParams.toArray();
        }

        return klass;
      }
    });

    if (desc.kind === 'field') {
      if (typeof desc.initializer === 'function') {
        qpDefinition.defaultValue = desc.initializer();
      }

      descriptor.initializer = function initializer() {
        return qpDefinition.defaultValue;
      };
    }

    return descriptor;
  }

  function queryParam(qpDefinition) {
    // Handle `@queryParam` usage
    if ("".concat(qpDefinition) === '[object Descriptor]') {
      return createDescriptor(qpDefinition);
    } // Handle `@queryParam()` usage


    return function (desc) {
      return createDescriptor(desc, qpDefinition);
    };
  }
});
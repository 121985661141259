define("ember-gridstack/templates/components/grid-stack-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cC3IfC12",
    "block": "[[[11,0],[16,1,[30,0,[\"guid\"]]],[24,0,\"grid-stack-item\"],[17,1],[4,[38,0],[[30,2]],null],[4,[38,1],[[30,0,[\"setup\"]]],null],[4,[38,2],[[30,0,[\"updateGridStack\"]],[30,2]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"grid-stack-item-content\"],[12],[1,\"\\n    \"],[18,3,[[30,0]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@options\",\"&default\"],false,[\"gs-item-attributes\",\"did-insert\",\"did-update\",\"yield\"]]",
    "moduleName": "ember-gridstack/templates/components/grid-stack-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("ember-collapsible-panel/components/cp-panel-toggle/component", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'a',
    classNames: ['cp-Panel-toggle'],
    classNameBindings: ['isOpen:cp-is-open'],
    // So taps register in iOS
    attributeBindings: ['href', 'ariaExpanded:aria-expanded'],
    href: '#',
    ariaExpanded: (0, _object.computed)('isOpen', function () {
      return (0, _object.get)(this, 'isOpen') ? 'true' : 'false';
    }),
    click: function click(e) {
      e.preventDefault();
      this.get('on-click')();
    }
  });

  _exports.default = _default;
});
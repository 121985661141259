define("ember-anchor/components/ember-anchor", ["exports", "@ember/component", "ember-anchor/mixins/view-support"], function (_exports, _component, _viewSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_viewSupport.default, {});

  _exports.default = _default;
});
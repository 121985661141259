define("ember-gridstack/templates/components/grid-stack", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Bbw68pmg",
    "block": "[[[11,0],[16,1,[30,0,[\"guid\"]]],[24,0,\"grid-stack\"],[4,[38,0],[[30,0,[\"setup\"]]],null],[4,[38,1],[[30,0,[\"update\"]],[30,1]],null],[12],[1,\"\\n  \"],[18,2,[[30,0]]],[1,\"\\n\"],[13]],[\"@options\",\"&default\"],false,[\"did-insert\",\"did-update\",\"yield\"]]",
    "moduleName": "ember-gridstack/templates/components/grid-stack.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
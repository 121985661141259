define("ember-math-helpers/helpers/pow", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.pow = pow;

  /**
   * Takes two or more numbers, using the first as the base number,
   * then using each subsequent number as an exponent to the previous value
   *
   * ```hbs
   * {{pow a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.pow`
   * @return {number} The base raised to all exponents
   */
  function pow(numbers) {
    return numbers.reduce(function (base, exponent) {
      return Math.pow(base, exponent);
    });
  }

  var _default = (0, _helper.helper)(pow);

  _exports.default = _default;
});
define("ember-data-model-fragments/array/stateful", ["exports", "@ember/utils", "@ember/array/proxy", "@ember/array", "ember-copy", "@ember/object", "ember-data-model-fragments/states"], function (_exports, _utils, _proxy, _array, _emberCopy, _object, _states) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    @module ember-data-model-fragments
  */

  /**
    A state-aware array that is tied to an attribute of a `DS.Model` instance.
  
    @class StatefulArray
    @namespace MF
    @extends Ember.ArrayProxy
  */
  var StatefulArray = _proxy.default.extend(_emberCopy.Copyable, {
    /**
      A reference to the array's owner record.
       @property owner
      @type {DS.Model}
    */
    owner: null,

    /**
      The array's property name on the owner record.
       @property name
      @private
      @type {String}
    */
    name: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._pendingData = undefined;
      (0, _object.set)(this, '_originalState', []);
    },
    content: (0, _object.computed)(function () {
      return (0, _array.A)();
    }),

    /**
      Copies the array by calling copy on each of its members.
       @method copy
      @return {array} a new array
    */
    copy: function copy() {
      return this.map(_emberCopy.copy);
    },

    /**
      @method setupData
      @private
      @param {Object} data
    */
    setupData: function setupData(data) {
      // Since replacing the contents of the array can trigger changes to fragment
      // array properties, this method can get invoked recursively with the same
      // data, so short circuit here once it's been setup the first time
      if (this._pendingData === data) {
        return;
      }

      this._pendingData = data;

      var processedData = this._normalizeData((0, _array.makeArray)(data));

      var content = (0, _object.get)(this, 'content'); // This data is canonical, so create rollback point

      (0, _object.set)(this, '_originalState', processedData); // Completely replace the contents with the new data

      content.replace(0, (0, _object.get)(content, 'length'), processedData);
      this._pendingData = undefined;
    },

    /**
      @method _normalizeData
      @private
      @param {Object} data
    */
    _normalizeData: function _normalizeData(data) {
      return data;
    },

    /**
      @method _createSnapshot
      @private
    */
    _createSnapshot: function _createSnapshot() {
      // Since elements are not models, a snapshot is simply a mapping of raw values
      return this.toArray();
    },

    /**
      @method _flushChangedAttributes
    */
    _flushChangedAttributes: function _flushChangedAttributes() {},

    /**
      @method _didCommit
      @private
    */
    _didCommit: function _didCommit(data) {
      if (data) {
        this.setupData(data);
      } else {
        // Fragment array has been persisted; use the current state as the original state
        (0, _object.set)(this, '_originalState', this.toArray());
      }
    },
    _adapterDidError: function _adapterDidError() {// No-Op
    },

    /**
      If this property is `true` the contents of the array do not match its
      original state. The array has local changes that have not yet been saved by
      the adapter. This includes additions, removals, and reordering of elements.
       Example
       ```javascript
      array.toArray(); // [ 'Tom', 'Yehuda' ]
      array.get('isDirty'); // false
      array.popObject(); // 'Yehuda'
      array.get('isDirty'); // true
      ```
       @property hasDirtyAttributes
      @type {Boolean}
      @readOnly
    */
    hasDirtyAttributes: (0, _object.computed)('[]', '_originalState', function () {
      return (0, _utils.compare)(this.toArray(), (0, _object.get)(this, '_originalState')) !== 0;
    }),

    /**
      This method reverts local changes of the array's contents to its original
      state.
       Example
       ```javascript
      array.toArray(); // [ 'Tom', 'Yehuda' ]
      array.popObject(); // 'Yehuda'
      array.toArray(); // [ 'Tom' ]
      array.rollbackAttributes();
      array.toArray(); // [ 'Tom', 'Yehuda' ]
      ```
       @method rollbackAttributes
    */
    rollbackAttributes: function rollbackAttributes() {
      this.setObjects((0, _object.get)(this, '_originalState'));
    },

    /**
      Method alias for `toArray`.
       @method serialize
      @return {Array}
    */
    serialize: function serialize() {
      return this.toArray();
    },
    arrayContentDidChange: function arrayContentDidChange() {
      this._super.apply(this, arguments);

      var record = (0, _object.get)(this, 'owner');
      var key = (0, _object.get)(this, 'name'); // Abort if fragment is still initializing

      if (record._internalModel._recordData.isStateInitializing()) {
        return;
      } // Any change to the size of the fragment array means a potential state change


      if ((0, _object.get)(this, 'hasDirtyAttributes')) {
        (0, _states.fragmentDidDirty)(record, key, this);
      } else {
        (0, _states.fragmentDidReset)(record, key);
      }
    },
    toStringExtension: function toStringExtension() {
      var ownerId = (0, _object.get)(this, 'owner.id');
      return "owner(".concat(ownerId, ")");
    }
  });

  var _default = StatefulArray;
  _exports.default = _default;
});
define("ember-models-table/utils/emt/themes/ep", ["exports", "@embroider/macros/es-compat", "@ember/debug"], function (_exports, _esCompat, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPaperToolbar = _exports.getPaperTabs = _exports.getPaperSelect = _exports.getPaperMenu = _exports.getPaperInput = _exports.getPaperIcon = _exports.getPaperDivider = _exports.getPaperContent = _exports.getPaperCheckbox = _exports.getPaperCard = _exports.getPaperButton = void 0;

  var getPaperInput = function getPaperInput() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-input")).default;
  };

  _exports.getPaperInput = getPaperInput;

  var getPaperButton = function getPaperButton() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-button")).default;
  };

  _exports.getPaperButton = getPaperButton;

  var getPaperMenu = function getPaperMenu() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-menu")).default;
  };

  _exports.getPaperMenu = getPaperMenu;

  var getPaperIcon = function getPaperIcon() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-icon")).default;
  };

  _exports.getPaperIcon = getPaperIcon;

  var getPaperDivider = function getPaperDivider() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-divider")).default;
  };

  _exports.getPaperDivider = getPaperDivider;

  var getPaperCheckbox = function getPaperCheckbox() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-checkbox")).default;
  };

  _exports.getPaperCheckbox = getPaperCheckbox;

  var getPaperSelect = function getPaperSelect() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-select")).default;
  };

  _exports.getPaperSelect = getPaperSelect;

  var getPaperTabs = function getPaperTabs() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-tabs")).default;
  };

  _exports.getPaperTabs = getPaperTabs;

  var getPaperToolbar = function getPaperToolbar() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-toolbar")).default;
  };

  _exports.getPaperToolbar = getPaperToolbar;

  var getPaperCard = function getPaperCard() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-card")).default;
  };

  _exports.getPaperCard = getPaperCard;

  var getPaperContent = function getPaperContent() {
    return (0, _esCompat.default)(require("ember-paper/components/paper-content")).default;
  };

  _exports.getPaperContent = getPaperContent;
});
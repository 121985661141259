define("ember-parachute/initializers/ember-parachute", ["exports", "@ember/routing/route", "rsvp", "@ember/runloop", "@ember/polyfills", "@ember/utils", "@ember/object/events", "ember", "ember-parachute/query-params", "ember-parachute/-private/parachute-event", "ember-parachute/utils/lookup-controller"], function (_exports, _route, _rsvp, _runloop, _polyfills, _utils, _events, _ember, _queryParams, _parachuteEvent, _lookupController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var canInvoke = _ember.default.canInvoke;
  var keys = Object.keys;

  function
    /* application */
  initialize() {
    if (_route.default._didInitializeParachute) {
      return;
    }

    _route.default.reopen({
      /**
       * Setup the route's `queryParams` map and call the `setup` hook
       * on the controller.
       *
       * @method setupController
       * @public
       * @param {Ember.Controller} controller
       * @returns {void}
       */
      setupController: function setupController(controller, model, transition) {
        this._super.apply(this, arguments);

        if (_queryParams.default.hasParachute(controller)) {
          this._setupParachuteQueryParamsMap(controller);

          var routeName = this.routeName;
          var event = new _parachuteEvent.default(routeName, controller, {}); // Overrides

          event.changed = event.changes;
          event.shouldRefresh = true;
          (0, _utils.tryInvoke)(controller, 'setup', [event, transition]);
          (0, _events.sendEvent)(controller, 'setup', [event, transition]);
        }
      },

      /**
       * Call the `reset` hook on the controller.
       *
       * @method resetController
       * @public
       * @param {Ember.Controller} controller
       * @param  {Boolean} isExiting
       * @returns {void}
       */
      resetController: function resetController(controller, isExiting) {
        this._super.apply(this, arguments);

        if (_queryParams.default.hasParachute(controller)) {
          var routeName = this.routeName;
          var event = new _parachuteEvent.default(routeName, controller, {}); // Overrides

          event.shouldRefresh = false;
          (0, _utils.tryInvoke)(controller, 'reset', [event, isExiting]);
          (0, _events.sendEvent)(controller, 'reset', [event, isExiting]);
        }
      },

      /**
       * For Engines support. `transition.routeInfos` is used to compute
       * the query params that will be injected into a controller. In lazily
       * loaded engines, routeInfos may be promises that don't contain the required
       * information. Resolve them here to guarantee parachute can properly function.
       *
       * @method deserialize
       * @param {Object} params the parameters extracted from the URL
       * @param {Transition} transition
       * @returns {Promise<any>} The model for this route
       */
      deserialize: function deserialize(params, transition) {
        // RouteInfo was introduced in 3.6 as a public api for HandlerInfo
        // so we should use that whenever possible.
        if (transition.routeInfos) {
          var routeInfos = transition.routeInfos; // Check if routeInfos have already been loaded.
          // If so, don't return a promise as it will result in
          // the loading screen/state flashing.

          if (routeInfos.every(function (x) {
            return x.isResolved;
          })) {
            return this._super(params, transition);
          } // Save and bind the refence to the super here
          // as this._super doesn't work in callbacks
          // https://github.com/emberjs/ember.js/issues/15291


          var _super = this._super.bind(this);

          return _rsvp.default.all(routeInfos.map(function (x) {
            return x.routePromise;
          })).then(function () {
            return _super(params, transition);
          });
        } else {
          var handlerInfos = transition.handlerInfos;

          if (!handlerInfos.find(function (x) {
            return !x.handler;
          })) {
            return this._super(params, transition);
          }

          var _super2 = this._super.bind(this);

          return _rsvp.default.all(handlerInfos.map(function (x) {
            return x.handlerPromise;
          })).then(function () {
            return _super2(params, transition);
          });
        }
      },

      /**
       * Serialize query param value if a given query param has a `serialize`
       * method.
       *
       * @private
       * @param {any} value
       * @param {string} urlKey
       * @returns {any}
       */
      serializeQueryParam: function serializeQueryParam(value, urlKey
      /**, defaultValueType **/
      ) {
        var controller = (0, _lookupController.default)(this);

        if (_queryParams.default.hasParachute(controller)) {
          var queryParam = _queryParams.default.lookupQueryParam(controller, urlKey);

          if (canInvoke(queryParam, 'serialize')) {
            return queryParam.serialize(value, controller);
          }
        }

        return this._super.apply(this, arguments);
      },

      /**
       * Deserialize query param value if a given query param has a `deserialize`
       * method.
       *
       * @private
       * @param {any} value
       * @param {string} urlKey
       * @returns {any}
       */
      deserializeQueryParam: function deserializeQueryParam(value, urlKey
      /**, defaultValueType **/
      ) {
        var controller = (0, _lookupController.default)(this);

        if (_queryParams.default.hasParachute(controller)) {
          var queryParam = _queryParams.default.lookupQueryParam(controller, urlKey);

          if (canInvoke(queryParam, 'deserialize')) {
            return queryParam.deserialize(value, controller);
          }
        }

        return this._super.apply(this, arguments);
      },

      /**
       * Schedule a QueryParamChangeEvent when query params change.
       *
       * @private
       * @param {string} routeName
       * @param {Ember.Controller} controller
       * @param {object} [changed={}]
       * @returns {void}
       */
      _scheduleParachuteChangeEvent: function _scheduleParachuteChangeEvent(routeName, controller) {
        var changed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        _runloop.run.schedule('afterRender', this, function () {
          var event = new _parachuteEvent.default(routeName, controller, changed);
          (0, _utils.tryInvoke)(controller, 'queryParamsDidChange', [event]);
          (0, _events.sendEvent)(controller, 'queryParamsDidChange', [event]);
        });
      },

      /**
       * Setup the route's `queryParams` map if it doesnt already exist from
       * the controller's Parachute meta.
       *
       * @method _setupParachuteQueryParamsMap
       * @private
       * @param {Ember.Controller} controller
       * @returns {void}
       */
      _setupParachuteQueryParamsMap: function _setupParachuteQueryParamsMap(controller) {
        if (!this.__hasSetupParachuteQPs) {
          var qpMap = this.get('queryParams');

          var _QueryParams$metaFor = _queryParams.default.metaFor(controller),
              qpMapForRoute = _QueryParams$metaFor.qpMapForRoute;

          keys(qpMapForRoute).forEach(function (key) {
            qpMapForRoute[key] = (0, _polyfills.assign)({}, qpMapForRoute[key], qpMap[key]);
          });
          this.set('queryParams', qpMapForRoute);
          this.__hasSetupParachuteQPs = true;
        }
      },
      actions: {
        /**
         * Route hook that fires when query params are changed.
         *
         * @public
         * @param {object} [changed={}]
         * @param {object} [present={}]
         * @param {object} [removed={}]
         * @returns {any}
         */
        queryParamsDidChange: function queryParamsDidChange() {
          var changed = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

          var _ = arguments.length > 1 ? arguments[1] : undefined;

          var removed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
          var controller = this.controller,
              routeName = this.routeName;

          if (_queryParams.default.hasParachute(controller)) {
            this._scheduleParachuteChangeEvent(routeName, controller, (0, _polyfills.assign)({}, changed, removed));
          }

          return this._super.apply(this, arguments);
        }
      }
    });

    _route.default.reopenClass({
      _didInitializeParachute: true
    });
  }

  var _default = {
    name: 'ember-parachute',
    initialize: initialize
  };
  _exports.default = _default;
});
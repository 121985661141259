define("ember-cli-string-helpers/helpers/underscore", ["exports", "@ember/component/helper", "@ember/string", "ember-cli-string-helpers/-private/create-string-helper"], function (_exports, _helper, _string, _createStringHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.underscore = _exports.default = void 0;
  var underscore = (0, _createStringHelper.default)(_string.underscore);
  _exports.underscore = underscore;

  var _default = (0, _helper.helper)(underscore);

  _exports.default = _default;
});